var emailForm = document.getElementById("footerForm");
if (emailForm){
    emailForm.addEventListener("submit", function (e) {
        e.preventDefault();
        var form = e.target;
        var actionUrl = form.getAttribute("action");
        var formData = new FormData(form);
        var xhr = new XMLHttpRequest();
        xhr.open("POST", actionUrl, true);
        var toastft = new bootstrap.Toast(toastContactFooter);
        toastft.show();
        xhr.send(formData);
        form.reset();
        console.log(toastft);
    });
}