var swiperBanner = new Swiper(".banner__swiper", {
    slidesPerView: 1,
    autoplay: {
        delay: 3000,
    },
    navigation: {
        nextEl: ".banner__swiper-button-next",
        prevEl: ".banner__swiper-button-prev",
    },
    pagination: {
        el: '.banner__swiper-pagination',
        clickable: true,
    },
});

var swiperFeedback = new Swiper(".feedback__swiper", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".feedback__swiper-button-next",
        prevEl: ".feedback__swiper-button-prev",
    },
    pagination: {
        el: '.feedback__swiper-pagination',
        clickable: true,
    },
    spaceBetween: 24,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        480: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 3,
        },
        1000: {
            slidesPerView: 3,
        }
    },
    autoplay: {
        delay: 3000,
    },
});

// window.onload = function () {
// Đội ngũ
var event_loadding = document.getElementById("loading-event-new");
if (event_loadding) {
    event_loadding.style.display = "none";
}

var team_loadding = document.getElementById("loading-team-slide");
if (team_loadding) {
    team_loadding.style.display = "none";
}

var team_slide = document.getElementById("team-slide");
if (team_slide) {
    team_slide.style.display = "block";
}

// Tại sao chọn chúng tôi
var why_choose_use_loading = document.getElementById("loading-choose-us");
if (why_choose_use_loading) {
    why_choose_use_loading.style.display = "none";
}

var why_choose_use_slide = document.getElementById("why-choose-us-slide");
if (why_choose_use_slide) {
    why_choose_use_slide.style.display = "block";
}

//Đối tác
var loading_partner = document.getElementById("loading-partner");
if (loading_partner) {
    loading_partner.style.display = "none";
}

var partner_slide = document.getElementById("partner-slide");
if (partner_slide) {
    partner_slide.style.display = "block";
}

//Tin tức
var loading_new = document.getElementById("new-loading");
if (loading_new) {
    loading_new.style.display = "none";
}

var new_slide = document.getElementById("new-slide");
if (new_slide) {
    new_slide.style.display = "block";
}

// Feedback loading element
var loading_feedback = document.getElementById("feedback-loading");
if (loading_feedback) {
    // Ẩn phần loading_feedback trước
    loading_feedback.classList.add("d-none");

    // Sau khi ẩn loading_feedback xong, hiển thị feedback_slide
    showFeedbackSlide();
}

function showFeedbackSlide() {
    // Feedback slide element
    var feedback_slide = document.getElementById("feedback-slide");
    if (feedback_slide) {
        // Hiển thị phần feedback_slide sau khi ẩn loading_feedback
        feedback_slide.classList.remove("d-none");
    }
}

const buildSwiperSlider = sliderElm => {
    return new Swiper(sliderElm, {
        autoplay: {
            delay: 3000,
        },
        spaceBetween: 24,
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 1,
            },
            640: {
                slidesPerView: 2,
            },
            1000: {
                slidesPerView: 3,
            }
        },
    })
}
const allSliders = document.querySelectorAll('.events__swiper');

allSliders.forEach(slider => {
    console.log(slider)
    buildSwiperSlider(slider);
});

var swiperPartner = new Swiper(".partner__swiper", {
    navigation: {
        nextEl: ".partner__swiper-button-next",
        prevEl: ".partner__swiper-button-prev",
    },
    pagination: {
        el: '.partner__swiper-pagination',
        clickable: true,
    },
    autoplay: {
        delay: 3000,
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 2,
        },
        640: {
            slidesPerView: 4,
        },
        1000: {
            slidesPerView: 6,
        }
    }
});

var swiperNews = new Swiper(".service .swiper", {
    spaceBetween: 24,
    navigation: {
        nextEl: ".service .swiper-button-next",
        prevEl: ".service .swiper-button-prev",
    },
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.service .swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        576: {
            slidesPerView: 2,
        },
    }
});

var swiperNews = new Swiper(".news__swiper", {
    spaceBetween: 24,
    navigation: {
        nextEl: ".news__swiper-button-next",
        prevEl: ".news__swiper-button-prev",
    },
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.news__swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        480: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 2,
        },
        1000: {
            slidesPerView: 3,
        }
    }
});

var swiperTeams = new Swiper(".teams__swiper", {
    spaceBetween: 24,
    navigation: {
        nextEl: ".teams__swiper-button-next",
        prevEl: ".teams__swiper-button-prev",
    },
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.teams__swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        576: {
            slidesPerView: 2
        },
        968: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        }
    }
});

var swiperGeneral = new Swiper(".general__list-swiper", {});
// };
