var contactForm = document.getElementById("contactForm");

if (contactForm) {
    contactForm.addEventListener("submit", function (e) {

        e.preventDefault();

        var form = e.target;
        var actionUrl = form.getAttribute("action");

        var full_name = document.querySelector("#contactForm input[name=full_name]").value;
        var phone_number = document.querySelector("#contactForm input[name=phone_number]").value;
        var email = document.querySelector("#contactForm input[name=email]").value;
        var note = document.querySelector("#contactForm textarea[name=note]").value;

        var data = {
            full_name: full_name,
            email: email,
            phone_number: phone_number,
            note: "Phản hồi của khách hàng : " + note,
        };

        var xhr = new XMLHttpRequest();
        xhr.open("POST", actionUrl, true);
        xhr.setRequestHeader("Content-Type", "application/json");

        form.reset();
        xhr.send(JSON.stringify(data));
    });
}

var formQuote = document.getElementById("formQuote");
var toastFormQuote = document.getElementById("toastFormQuote");
if(formQuote) {
    formQuote.addEventListener('submit', function(e) {
        e.preventDefault();

        var form = e.target;
        var actionUrl = form.getAttribute("action");

        var full_name = document.querySelector("#formQuote input[name=full_name]").value;
        var phone_number = document.querySelector("#formQuote input[name=phone_number]").value;
        var note = document.querySelector("#formQuote textarea[name=note]").value;
        var address = document.querySelector("#formQuote input[name=address]").value;

        var data = {
            full_name: full_name,
            phone_number: phone_number,
            note: note,
            address: address,
        };

        var xhr = new XMLHttpRequest();
        xhr.open("POST", actionUrl, true);
        xhr.setRequestHeader("Content-Type", "application/json");

        form.reset();
        xhr.send(JSON.stringify(data));

        var toast = new bootstrap.Toast(toastFormQuote);
        toast.show();
    })
}

var formQuoteHome = document.getElementById("formQuoteHome");

if(formQuoteHome) {
    formQuoteHome.addEventListener('submit', function(e) {
        e.preventDefault();

        var form = e.target;
        var actionUrl = form.getAttribute("action");

        var full_name = document.querySelector("#formQuoteHome input[name=full_name]").value;
        var phone_number = document.querySelector("#formQuoteHome input[name=phone_number]").value;
        var note = document.querySelector("#formQuoteHome textarea[name=note]").value;
        var address = document.querySelector("#formQuoteHome input[name=address]").value;

        var data = {
            full_name: full_name,
            phone_number: phone_number,
            note: note,
            address: address,
        };

        var xhr = new XMLHttpRequest();
        xhr.open("POST", actionUrl, true);
        xhr.setRequestHeader("Content-Type", "application/json");

        form.reset();
        xhr.send(JSON.stringify(data));
        var toast = new bootstrap.Toast(toastFormQuote);
        toast.show();
    })
}
